<template>
  <section :class="[hasFullHeigth ? 'h-full' : '', hasFullWidth ? 'w-full pr-1' : '']">
    <div
      class="flex flex-col justify-between shadow sm:rounded-md overflow-hidden"
      :class="[hasFullHeigth ? 'h-full' : 'w-full', hasFullWidth ? 'w-full' : '']"
    >
      <div class="flex flex-col bg-white py-6 px-4 sm:p-6" :class="[hasFullHeigth ? 'h-full' : '']">
        <div class="flex justify-between pb-4">
          <div>
            <h2 id="details_heading" class="flex-1 text-lg pb-1 leading-6 font-medium text-gray-900">{{ header }}</h2>
            <p class="text-sm text-gray-500">
              {{ headerCaption }}
            </p>
          </div>
          <div class="flex-1">
            <slot name="header"></slot>
          </div>
        </div>
        <slot name="body"></slot>
      </div>
      <div v-if="hasFooter === true" class="px-4 py-3 flex justify-end bg-white text-right sm:px-6 border-t">
        <slot name="footer"></slot>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DetailsSection",
  components: {},
  props: {
    header: {
      type: String
    },
    headerCaption: {
      type: String,
      default: ""
    },
    hasFooter: {
      type: Boolean,
      default: true
    },
    hasFullHeigth: {
      type: Boolean,
      default: false
    },
    hasFullWidth: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {};
  }
});
</script>
