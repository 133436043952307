import { computed } from "vue";
// Graphql
import useAuth from "@/api/auth/useAuth";
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";
import { EmployeeWorkAreasQuery } from "@/graphql/types";
// demand template
import { EMPLOYEE_WORK_AREAS } from "@/graphql/employeeWorkAreas/queries/employeeWorkAreas";
import { CREATE_EMPLOYEE_WORK_AREA } from "@/graphql/employeeWorkAreas/mutations/createEmployeeWorkArea";
import { DELETE_EMPLOYEE_WORK_AREA } from "@/graphql/employeeWorkAreas/mutations/deleteEmployeeWorkArea";
// other
import produce from "immer";

/**
 ***************************
 * Helper functions
 ***************************
 */

/**
 ***************************
 * Store hook
 ***************************
 */

export default function useEmployeeWorkAreaStore(options?: { allEmployeeWorkAreas?: boolean }) {
  const { tenantId } = useAuth();

  /**
   * Query all employeeWorkAreas
   */

  const employeeWorkAreasQueryVariables = computed(() => {
    return { tenantId: tenantId.value };
  });

  const {
    result: employeeWorkAreasResult,
    loading: employeeWorkAreasLoading,
    error: employeeWorkAreasError
  } = useQuery<EmployeeWorkAreasQuery>(EMPLOYEE_WORK_AREAS, employeeWorkAreasQueryVariables.value, () => ({
    enabled: options?.allEmployeeWorkAreas === true
  }));
  const employeeWorkAreas = useResult(employeeWorkAreasResult, [], data => data.employee_work_area);

  /**
   * Create demand template
   */

  const {
    mutate: createEmployeeWorkArea,
    loading: createEmployeeWorkAreaLoading,
    error: createEmployeeWorkAreaError,
    onDone: onCreateEmployeeWorkAreaSuccess
  } = useMutation(CREATE_EMPLOYEE_WORK_AREA, {
    update: (cache, { data: { insert_employee_work_area_one } }) => {
      // read employees data from cache
      // DON'T FORGET VARIABLES
      const employeeWorkAreaData = cache.readQuery<EmployeeWorkAreasQuery>({
        query: EMPLOYEE_WORK_AREAS,
        variables: employeeWorkAreasQueryVariables.value
      });

      // add employee to work area employee list
      if (employeeWorkAreaData) {
        // update data if query is already in cache
        // add shift to shift list of schedule
        const employeeWorkAreaUpdate = produce(employeeWorkAreaData.employee_work_area, draftState => {
          // add shift to state
          draftState.push(insert_employee_work_area_one);
        });

        // write data back to cache
        // DON'T FORGET VARIABLES
        cache.writeQuery({
          query: EMPLOYEE_WORK_AREAS,
          variables: employeeWorkAreasQueryVariables.value,
          data: { employee_work_area: employeeWorkAreaUpdate }
        });
      }
    }
  });

  const onCreateEmployeeWorkArea = (workAreaId: string, employeeId: string) => {
    createEmployeeWorkArea({
      workAreaId: workAreaId,
      employeeId: employeeId,
      tenantId: tenantId.value
    });
  };

  /**
   * Delete demand template
   */

  const {
    mutate: deleteEmployeeWorkArea,
    loading: deleteEmployeeWorkAreaLoading,
    error: deleteEmployeeWorkAreaError,
    onDone: onDeleteEmployeeWorkAreaSuccess
  } = useMutation(DELETE_EMPLOYEE_WORK_AREA, {
    update: (cache, { data: { delete_employee_work_area } }) => {
      // read employees data from cache
      // DON'T FORGET VARIABLES
      const employeeWorkAreaData = cache.readQuery<EmployeeWorkAreasQuery>({
        query: EMPLOYEE_WORK_AREAS,
        variables: employeeWorkAreasQueryVariables.value
      });

      const deletedId = delete_employee_work_area.returning[0].id;

      // remove employee form work area employee list
      if (employeeWorkAreaData) {
        // remove shift from schedule shift list
        const employeeWorkAreaUpdate = produce(employeeWorkAreaData.employee_work_area, draftState => {
          // remove shift from state
          const index = draftState.findIndex(item => item.id === deletedId);
          if (index !== -1) draftState.splice(index, 1);
        });

        // write data back to cache
        // DON'T FORGET VARIABLES
        cache.writeQuery({
          query: EMPLOYEE_WORK_AREAS,
          variables: employeeWorkAreasQueryVariables.value,
          data: { employee_work_area: employeeWorkAreaUpdate }
        });
      }
    }
  });

  const onDeleteEmployeeWorkArea = (workAreaId: string, employeeId: string) =>
    deleteEmployeeWorkArea({
      workAreaId: workAreaId,
      employeeId: employeeId,
      tenantId: tenantId.value
    });

  /**
   * Status
   */

  const loading = computed(() => {
    if (employeeWorkAreasLoading.value === true) return true;
    else if (createEmployeeWorkAreaLoading.value === true) return true;
    else if (deleteEmployeeWorkAreaLoading.value === true) return true;
    else return false;
  });

  const error = computed(() => {
    if (employeeWorkAreasError.value) return employeeWorkAreasError.value;
    else if (createEmployeeWorkAreaError.value) return createEmployeeWorkAreaError.value;
    else if (deleteEmployeeWorkAreaError.value) return deleteEmployeeWorkAreaError.value;
    else return null;
  });

  /**
   * Other
   */

  return {
    // error status
    loading,
    error,
    // queries
    employeeWorkAreas,
    // create
    onCreateEmployeeWorkArea,
    onCreateEmployeeWorkAreaSuccess,
    // delete
    onDeleteEmployeeWorkArea,
    onDeleteEmployeeWorkAreaSuccess
  };
}
