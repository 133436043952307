import gql from "graphql-tag";

import { employeeBaseFragment } from "@/graphql/employees/fragments/employeeBase";
import { workAreaBaseFragment } from "@/graphql/workAreas/fragments/workAreaBase";
import { employeeWorkAreaBaseFragment } from "@/graphql/employeeWorkAreas/fragments/employeeWorkAreaBase";

// get all users with role employee
export const EMPLOYEE_WORK_AREAS = gql`
  query employee_work_areas($tenantId: uuid!) {
    employee_work_area(where: { tenant_id: { _eq: $tenantId } }) {
      ...EmployeeWorkAreaBase
      employee {
        ...EmployeeBase
        id
      }
      work_area {
        ...WorkAreaBase
        id
      }
    }
  }
  ${employeeWorkAreaBaseFragment.employeeWorkAreaBase}
  ${employeeBaseFragment.employeeBase}
  ${workAreaBaseFragment.workAreaBase}
`;
