
import { defineComponent } from "vue";

export default defineComponent({
  name: "DetailsSection",
  components: {},
  props: {
    header: {
      type: String
    },
    headerCaption: {
      type: String,
      default: ""
    },
    hasFooter: {
      type: Boolean,
      default: true
    },
    hasFullHeigth: {
      type: Boolean,
      default: false
    },
    hasFullWidth: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {};
  }
});
