import gql from "graphql-tag";

// delete employee
export const DELETE_EMPLOYEE_WORK_AREA = gql`
  mutation deleteEmployeeWorkArea($employeeId: uuid!, $workAreaId: uuid!, $tenantId: uuid!) {
    delete_employee_work_area(
      where: { employee_id: { _eq: $employeeId }, work_area_id: { _eq: $workAreaId }, tenant_id: { _eq: $tenantId } }
    ) {
      affected_rows
      returning {
        id
        employee_id
        work_area_id
      }
    }
  }
`;
