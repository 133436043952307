import gql from "graphql-tag";

export const employeeWorkAreaBaseFragment = {
  employeeWorkAreaBase: gql`
    fragment EmployeeWorkAreaBase on employee_work_area {
      id
      employee_id
      work_area_id
    }
  `
};
