import gql from "graphql-tag";

import { employeeBaseFragment } from "@/graphql/employees/fragments/employeeBase";
import { workAreaBaseFragment } from "@/graphql/workAreas/fragments/workAreaBase";
import { employeeWorkAreaBaseFragment } from "@/graphql/employeeWorkAreas/fragments/employeeWorkAreaBase";

// create new employee with contract
export const CREATE_EMPLOYEE_WORK_AREA = gql`
  mutation createEmployeeWorkArea($employeeId: uuid!, $workAreaId: uuid!, $tenantId: uuid!) {
    insert_employee_work_area_one(
      object: { employee_id: $employeeId, work_area_id: $workAreaId, tenant_id: $tenantId }
    ) {
      ...EmployeeWorkAreaBase
      employee {
        ...EmployeeBase
      }
      work_area {
        ...WorkAreaBase
      }
    }
  }
  ${employeeWorkAreaBaseFragment.employeeWorkAreaBase}
  ${employeeBaseFragment.employeeBase}
  ${workAreaBaseFragment.workAreaBase}
`;
