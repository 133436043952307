
import { defineComponent, computed } from "vue";
import DetailsSection from "@/components/details/DetailsSection.vue";
import EmployeeTable from "@/features/employees/table/EmployeeTable.vue";
// store
import useEmployeeStore from "@/store/useEmployeeStore";
import useEmployeeWorkAreaStore from "@/store/useEmployeeWorkAreaStore";
// other
import { useRoute } from "vue-router";
import { repairUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "WorkAreaDetailsPageEmployees",
  components: {
    DetailsSection,
    EmployeeTable
  },
  setup() {
    //store
    const { employees } = useEmployeeStore({ allEmployees: true });
    const {
      employeeWorkAreas,
      onCreateEmployeeWorkArea,
      onCreateEmployeeWorkAreaSuccess,
      onDeleteEmployeeWorkArea,
      onDeleteEmployeeWorkAreaSuccess
    } = useEmployeeWorkAreaStore({
      allEmployeeWorkAreas: true
    });

    // get work area id
    const {
      params: { workAreaId: workAreaUrlId }
    } = useRoute();
    const workAreaId = computed(() => repairUuid(workAreaUrlId));

    // get assignments of considered employee
    const employeeWorkAreasFiltered = computed(() =>
      employeeWorkAreas.value.filter(item => item.work_area_id === workAreaId.value)
    );
    // get employee ids
    const employeeIds = computed(() => employeeWorkAreasFiltered.value.map(item => item.employee_id));

    onCreateEmployeeWorkAreaSuccess(() => {
      console.info("Employee assigned to work area");
    });

    onDeleteEmployeeWorkAreaSuccess(() => console.info("Employee removed from work area"));

    // remove or add employee to work area
    const onSelectEmployee = (employeeId: string) => {
      // remove
      if (employeeIds.value?.includes(employeeId)) {
        onDeleteEmployeeWorkArea(workAreaId.value, employeeId);
        // add
      } else {
        onCreateEmployeeWorkArea(workAreaId.value, employeeId);
      }
    };

    return {
      employees,
      employeeIds,
      onSelectEmployee
    };
  }
});
